@use "../01_settings/settings";
@use "sass:map";

@mixin tablet {
  @media (min-width: #{map.get(settings.$layout-breakpoints, md)}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{map.get(settings.$layout-breakpoints, lg)}) {
    @content;
  }
}

@mixin only-mobile {
  @media (max-width: #{map.get(settings.$layout-breakpoints, md) - .0625rem}) {
    @content;
  }
}
