@use '../01_settings/settings';
@use '../02_tools/tools';
@use 'sass:map';

.row {
	display: grid;
	grid-gap: map.get(settings.$layout-grid-row-gap, sm) map.get(settings.$layout-grid-column-gap, sm);
	grid-template-columns: repeat(12, [col-start] 1fr);
	@include tools.tablet {
		grid-column-gap: map.get(settings.$layout-grid-column-gap, md);
	}
	@include tools.desktop {
		grid-gap: map.get(settings.$layout-grid-row-gap, lg) map.get(settings.$layout-grid-column-gap, lg);
	}
}

@for $i from 1 through 12 {
	.col-#{$i} {
		grid-column-end: span #{$i};
	}
	.col-offset-#{$i} {
		grid-column-start: #{$i + 1};
	}
}

@for $i from 1 through 12 {
	.md\:col-#{$i} {
		@include tools.tablet {
			grid-column-end: span #{$i};
		}
	}
	.md\:col-offset-#{$i} {
		@include tools.tablet {
			grid-column-start: #{$i + 1};
		}
	}
}

@for $i from 1 through 12 {
	.lg\:col-#{$i} {
		@include tools.desktop {
			grid-column-end: span #{$i};
		}
	}
	.lg\:col-offset-#{$i} {
		@include tools.desktop {
			grid-column-start: #{$i + 1};
		}
	}
}

.col-hide {
	@include tools.only-mobile {
		display: none;
	}
}

.col-show {
	display: none;
	@include tools.only-mobile {
		display: block;
	}
}
