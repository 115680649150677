@use '../02_tools/tools';
@use 'global-text';

.text-h1 {
  @include tools.reset-text;
  @include global-text.text-h1();
}

.text-h2 {
  @include tools.reset-text;
  @include global-text.text-h2();
}

.text-h3 {
  @include tools.reset-text;
  @include global-text.text-h3();
}

.text-h4 {
  @include tools.reset-text;
  @include global-text.text-h4();
}

.text-h5 {
  @include tools.reset-text;
  @include global-text.text-h5();
}

.text-body {
  @include tools.reset-text;
  @include global-text.text-body();
}

.text-body-lite {
  @include tools.reset-text;
  @include global-text.text-body-lite();
}

.text-link {
  @include tools.reset-text;
  @include global-text.text-link();
}

.font-weight-300 {
  font-weight: 300;
}

.font-weight-400 {
  font-weight: 400;
}

.font-weight-bold {
  font-weight: bold;
}
