@use '../02_tools/tools';

@mixin text-h1 {
	font-size: 2rem;
	line-height: 3rem;

	@include tools.desktop {
		font-size: 3rem;
		line-height: 4rem;
	}
}

@mixin text-h2 {
	font-size: 1.75rem;
	line-height: 2.625rem;

	@include tools.desktop {
		font-size: 2.5rem;
		line-height: 3.5rem;
	}
}

@mixin text-h3 {
	font-size: 1.5rem;
	line-height: 2.25rem;

	@include tools.desktop {
		font-size: 2rem;
		line-height: 2.75rem;
	}
}

@mixin text-h4 {
	font-size: 1.125rem;
	line-height: 1.625rem;

	@include tools.desktop {
		font-size: 1.5rem;
		line-height: 2.25rem;
	}
}

@mixin text-h5 {
	font-size: 1rem;
	line-height: 1.5rem;

	@include tools.desktop {
		font-size: 1.125rem;
		line-height: 1.875rem;
	}
}

@mixin text-body {
	font-size: 0.875rem;
	line-height: 1.375rem;

	@include tools.desktop {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

@mixin text-body-lite {
	font-size: 0.75rem;
	line-height: 1.175rem;

	@include tools.desktop {
		font-size: 0.875rem;
		line-height: 1.25rem;
	}
}

@mixin text-link {
	font-weight: bold;
	font-size: 0.875rem;
	line-height: 1.375rem;

	@include tools.desktop {
		font-size: 1rem;
		line-height: 1.5rem;
	}
}

@mixin text-modal-title {
	[slot='title'],
	[slot='title-warning'] {
		display: flex;
		width: 100%;
		justify-content: center;

		h1,
		h2 {
      width: 100%;
			font-size: 1.5rem;
			color: #333333;
			font-weight: bold;
			line-height: 2.5rem;
			padding: 0rem;
			margin: 0rem;
			text-align: center;

			@include tools.only-mobile {
				font-size: 1.375rem;
				line-height: 2rem;
			}
		}
		#modalVerificationTitle {
			@include tools.only-mobile {
				font-size: 1.375rem;
				padding: 0 1.5rem;
			}
		}
	}
	.imv-modal__title {
		@include tools.only-mobile {
			line-height: 1.5625rem;
			text-align: left;
		}
	}
	[slot='text'] {
		text-align: left;
		@include tools.only-mobile {
			&.lh-m {
				line-height: 1.5rem;
			}
			&.overflow-auto {
				max-height: 18rem;
				overflow: auto;
			}
		}
	}
	[slot='content__email'] {
		text-align: left;
		.imv-modal__info {
			margin: 1rem 0 1rem;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			img {
				margin: 0 0.5rem;
			}
			a.imv-modal__info-text {
				color: var(--theme-color-link);
				text-decoration: underline;
			}
		}
	}
	@include tools.only-mobile {
		imv-form-control {
			width: 100%;
		}
		imv-ui-note {
			[slot='text'] {
				margin-top: -1.5rem;
			}
			margin-top: -1.5rem;
		}
		[slot='content'],
		#dialog-success-content {
			text-align: left;
			white-space: break-spaces;
			line-height: 1.5rem;
		}
		[slot='actions'] {
			@include tools.only-mobile {
				width: 100%;
				margin: 1.5rem 0 0.5rem;
				&.mt-0 {
					margin-top: 0;
				}
				.mt-0 {
					margin-top: 0;
				}
				&.m-0 {
					margin: 0;
				}
				&.ml--2,
				.ml--2 {
					margin-left: -2rem;
				}
				&.ml--1,
				.ml--1 {
					margin-left: -1.5rem;
				}
				&.two-buttons {
					display: flex;
					gap: 1.5rem;
				}
			}
			imv-ui-button {
				&.mobile-full-width {
					@include tools.only-mobile {
						width: 100%;
					}
				}
			}
		}
	}
}

@mixin action-box-styles {
	[slot='actions'] {
		margin-top: 0;
		form {
			margin-top: 1.5rem;
		}
		display: flex;
		align-items: center;
		margin-bottom: 0.5rem;
		justify-content: space-between;
		@include tools.only-mobile {
			max-width: 100%;
			flex-direction: column;
			height: auto;
			justify-content: space-between;
			align-items: flex-start;
		}
		form {
			width: 56%;
			@include tools.only-mobile {
				width: 100%;
			}
		}
		#dialog-control-accept {
			width: 40%;
			text-align: right;
			padding: 0;
			@include tools.only-mobile {
				text-align: left;
				width: 100%;
			}
		}
    // the height of the text field is matched to that of the check button in the CSV modal
    label#dialog-control-label {
      @include tools.tablet {
        max-height: 3.125rem;
      }
    }
		imv-ui-button {
			margin-top: 1.5rem;
		}
		imv-ui-button[eid='confirm-accept'],
		imv-ui-button[eid='confirm-cancel-modal-button'],
		imv-ui-button:nth-of-type(2) {
			@include tools.only-mobile {
				margin: 1.5rem 0 0 -1.5rem;
			}
		}
	}
}
