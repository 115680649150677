/**
 * Force reset file
 *
 * In this file you can define mixins that are used to reset
 * specific html tags
 */

// Sample
@mixin reset-text {
	margin: 0;
	padding: 0;

	font-weight: normal;
	font-size: 100%;
}
