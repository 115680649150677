@use '../01_settings/settings';
@use '../02_tools/tools';

.width-100 {
  width: 100%;
}

.height-100 {
  height: 100%;
}

$spacing-positions: (
  t: 'top',
  b: 'bottom',
  r: 'right',
  l: 'left',
);

$spacing-properties: (
  m: 'margin',
  p: 'padding',
);

@each $unit-name, $unit-value in settings.$layout-spacing {
  @each $property-key, $property-name in $spacing-properties {
    .#{$property-key}-#{$unit-name} {
      #{$property-name}: $unit-value;
    }
    @each $position-key, $position-name in $spacing-positions {
      .#{$property-key}#{$position-key}-#{$unit-name} {
        #{$property-name}-#{$position-name}: $unit-value;
      }
      .md\:#{$property-key}#{$position-key}-#{$unit-name} {
        @include tools.tablet {
          #{$property-name}-#{$position-name}: $unit-value;
        }
      }
      .lg\:#{$property-key}#{$position-key}-#{$unit-name} {
        @include tools.desktop {
          #{$property-name}-#{$position-name}: $unit-value;
        }
      }
    }
  }
}
