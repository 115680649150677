html {
	margin: 0;
	padding: 0;

	word-break: break-word;

	cursor: default;
	text-rendering: optimizelegibility;
	scroll-behavior: smooth;

	body {
		margin: 0;
		padding: 0;

		color: var(--theme-color-grey-dark);
		font-weight: normal;
		font-size: 1em;
		font-family: var(--theme-fontFamily-main);
	}
}
