
@import '../scss/99_utilities/global-text';
@import 'pixel-to-rem.scss';

imv-ui-modal {
	[slot='title'] {
		width:  calc(100% - rem(40px));
	}
}

imv-ui-modal.modal-align-title.hydrated {
	@include text-modal-title
}

imv-ui-modal.modal-aign-butons{
	@include action-box-styles
}