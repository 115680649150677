/* You can add global styles to this file, and also import other style files */
@use './scss/styles.scss';
@import 'pixel-to-rem.scss';
@import './styles/imv-ui-modal.scss';

body {
	display: flex;
	justify-content: center;
	overflow-x: hidden;

	background-color: #f8fafd;
}

.imv-wrapper {
	position: relative;

	flex: 1;
	width: 100%;
	max-width: rem(1440px);
	margin: 0 auto;

	&--flex {
		display: flex;
		align-items: center;
	}

	&--spacing {
		padding: rem(24px, 32px);
	}
}

imv-ui-note.inner-html {
	div[slot='text'],
	div[slot='fulltext'] {
		margin-top: -1em;
	}
}

imv-photo {
	margin: rem(50px) auto;
}
