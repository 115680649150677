@import 'pixel-to-rem.scss';

$images-path: '/assets/images';

$font-path: '/assets/fonts';

$layout-grid-column-gap: (
	sm: rem(8px),
	md: rem(16px),
	lg: rem(32px),
);

$layout-grid-row-gap: (
	sm: rem(16px),
	lg: rem(24px),
);

$layout-grid-gap-outside: rem(24px);

$layout-wrapper: 85.375rem;

$layout-breakpoints: (
	sm: rem(360px),
	md: rem(768px),
	lg: rem(1440px),
);

$layout-spacing: (
	1x: rem(8px),
	2x: rem(16px),
	3x: rem(24px),
	4x: rem(32px),
	5x: rem(40px),
	6x: rem(48px),
	7x: rem(64px),
	8x: rem(80px),
);
